* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
} /* makes 1rem 10px */
body {
  margin: 0;
  padding: 0;
  font-size: 1.8em;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  line-height: 1.3;
  color: #292929;
  min-width: 100%;
  min-height: 100%;
}
/* headings */
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.6rem;
}
h6 {
  font-size: 1.4rem;
}

.mainContainer {
  background-color: #78b084;
  height: 100vh;
  padding: 15px;
}

.loadFileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

.button {
  display: inline-block;
  background-color: #1e88e5;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #1976d2;
}

.button:disabled {
  background-color: #bfbfbf; /* Light gray color when disabled */
  cursor: not-allowed;
}

.settingsLine {
  display: flex;
  margin-bottom: 10px;
}

.select-container {
  margin-right: 20px;
}

.checkBoxes {
  display: flex;
  flex-wrap: wrap;
}

/* Checkboxes */
/* Style the container for each checkbox */
.singleCheckBox {
  flex-basis: 15%;
  margin: 5px;
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}

/* Hide the default checkbox */
.singleCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.singleCheckBox:hover .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.singleCheckBox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.singleCheckBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.createReportButton {
  display: flex;
  justify-content: center;
}

.react-select__control {
  width: 200px;
}

.react-datepicker-wrapper {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

.react-datepicker__view-calendar-icon input {
  font-size: 16px;
}

.daysContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  font-size: 25px;
}

.daysChildContainer {
  margin: 10px;
}

.daysChildContainerText {
  font-weight: bold;
}

.form5-input {
  padding: 6px 10px 5px 5px;
  font-size: 16px;
}